<script setup lang="ts">
import { DateTime } from "luxon";
import { computed, ref, watch } from "vue";
import { ArrowPathIcon, ArrowDownTrayIcon } from "@heroicons/vue/20/solid";
import { useRoute } from "vue-router";
import Alipay from "../assets/images/payments/alipay.svg";
import Amex from "../assets/images/payments/amex.svg";
import Discover from "../assets/images/payments/discover.svg";
import JCB from "../assets/images/payments/jcb.svg";
import Mastercard from "../assets/images/payments/mastercard.svg";
import UnionPay from "../assets/images/payments/unionpay.svg";
import Visa from "../assets/images/payments/visa.svg";
import WechatPay from "../assets/images/payments/wechatpay.svg";
import { useLoading } from "../composables/useLoading";
import { useNotification } from "../composables/useNotification";
import { UserStore, useStores } from "../composables/useStores";
import { FirestoreService } from "../services/firestore";
import { Payment } from "../types/payment";
import EftposAustralia from "../assets/images/payments/eftpos.png";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import JsonCsv from "vue-json-csv";

import { Store } from "../types/store";
const { setText, toggleLoading } = useLoading();
const { setError } = useNotification();
const { currentStore } = useStores();
const payments = ref<Payment[]>([]);
const init = async (store: UserStore, date: string) => {
  setText("Loading payments...");
  toggleLoading(true);
  try {
    const path = `/users/${store.user}/merchants/${store.merchant}/stores/${store.store}/days/${date}/payments`;
    payments.value = await FirestoreService.getCollection<Payment>(path);
  } catch (e) {
    setError("failed to load payments please try again");
  } finally {
    toggleLoading(false);
  }
};
const route = useRoute();
const currentDate = ref(route.params.date as string);
watch(
  [currentDate, currentStore],
  async () => {
    if (!currentDate.value) return;
    if (!currentStore.value) return;
    await init(currentStore.value, currentDate.value);
  },
  { immediate: true }
);
const paymentMethodToImage = (paymentMethod: string) => {
  switch (paymentMethod.toLowerCase()) {
    case "visa":
      return Visa;
    case "alipay":
      return Alipay;
    case "mc":
      return Mastercard;
    case "jcb":
      return JCB;
    case "amex":
      return Amex;
    case "discover":
      return Discover;
    case "cup":
      return UnionPay;
    case "wechatpay":
      return WechatPay;
    case "eftpos_australia":
      return EftposAustralia;
    default:
      return;
  }
};
const zone = (store: Store) => {
  if (store.address?.state === "VIC") {
    return "Australia/Melbourne";
  }
  return "Australia/Brisbane";
};
const downloadable = computed(() => {
  if (!payments.value) return;
  if (payments.value.length === 0) return;
  if (!currentStore.value) return;
  return JSON.stringify(
    payments.value.map((payment) => {
      return {
        Date: DateTime.fromISO(payment.date)
          .setZone(zone(currentStore.value!.data))
          .toFormat("yyyy-MM-dd HH:mm:ss"),
        Total: (payment.amount.amount ?? 0) / 100,
        Surcharge: Payment.surcharge(payment) / 100,
        Fees: (Payment.commission(payment) ?? 0) / 100,
        Net: (Payment.net(payment) ?? 0) / 100,
        PaymentMethod: payment.paymentMethod,
        Status: payment.refundTotal ? "Refunded" : "Authorised",
      };
    })
  );
});
</script>
<template>
  <div class="px-4 pb-4 sm:pb-6 sm:px-6 lg:px-8 lg:pb-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <div class="sm:flex sm:items-center mb-6">
          <div
            class="sm:flex-auto flex flex-row justify-start space-x-2 items-center"
          >
            <h1 class="text-[#111827] font-poppins font-semibold text-2xl">
              Payments
            </h1>
            <arrow-path-icon
              class="w-6 h-6 text-black block cursor-pointer"
              @click="currentStore && init(currentStore, currentDate)"
            />
            <json-csv
              :data="downloadable"
              v-if="downloadable && currentStore"
              :name="`${currentStore.data.description}-payments-${currentDate}`"
            >
              <arrow-down-tray-icon
                class="w-6 h-6 text-black block cursor-pointer"
              />
            </json-csv>
          </div>
        </div>
        <div class="w-[200px] max-w-full my-4">
          <VueDatePicker
            v-model="currentDate"
            format="yyyy-MM-dd"
            model-type="yyyy-MM-dd"
            :enable-time-picker="false"
          />
        </div>
      </div>
    </div>
    <div class="p-4 bg-white rounded-[16px]">
      <div class="-mx-4 mt-8 sm:-mx-0">
        <table class="min-w-full" v-if="currentStore">
          <thead class="border-none">
            <tr class="border-none">
              <th
                scope="col"
                class="rounded-l-[16px] hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Date
              </th>

              <th
                scope="col"
                class="hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Total
              </th>
              <th
                scope="col"
                class="hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Surcharge
              </th>
              <th
                scope="col"
                class="hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Fees
              </th>
              <th
                scope="col"
                class="hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Net
              </th>
              <th
                scope="col"
                class="hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Payment Method
              </th>
              <th
                scope="col"
                class="rounded-r-[16px] hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Status
              </th>
              <th
                scope="col"
                class="rounded-r-[16px] hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                ID
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr
              v-for="payment in payments.sort((p1, p2) =>
                DateTime.fromISO(p1.date).toMillis() <
                DateTime.fromISO(p2.date).toMillis()
                  ? 1
                  : -1
              )"
              :key="payment.id"
            >
              <td
                class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0"
              >
                {{
                  DateTime.fromISO(payment.date)
                    .setZone(zone(currentStore.data))
                    .toFormat("yyyy-MM-dd HH:mm:ss")
                }}
                <dl class="font-normal lg:hidden">
                  <dt class="sr-only">Amount</dt>
                  <dd class="mt-1 truncate text-gray-700">
                    {{
                      (payment.amount.amount / 100).toLocaleString("en-AU", {
                        style: "currency",
                        currency: "AUD",
                      })
                    }}
                  </dd>
                </dl>
              </td>

              <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {{
                  (Payment.total(payment) / 100).toLocaleString("en-AU", {
                    style: "currency",
                    currency: "AUD",
                  })
                }}
              </td>
              <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {{
                  (Payment.surcharge(payment) / 100).toLocaleString("en-AU", {
                    style: "currency",
                    currency: "AUD",
                  })
                }}
              </td>
              <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {{
                  (Payment.commission(payment) / 100).toLocaleString("en-AU", {
                    style: "currency",
                    currency: "AUD",
                  })
                }}
              </td>
              <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {{
                  (Payment.net(payment) / 100).toLocaleString("en-AU", {
                    style: "currency",
                    currency: "AUD",
                  })
                }}
              </td>
              <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                <div class="flex flex-row justify items-center">
                  <img
                    :src="paymentMethodToImage(payment.paymentMethod)"
                    v-if="paymentMethodToImage(payment.paymentMethod)"
                    class="block wx-auto w-[50px] h-auto"
                  />

                  <p v-else>{{ payment.paymentMethod }}</p>
                </div>
              </td>
              <td class="px-3 py-4 text-sm text-gray-500">
                {{
                  payment.refundTotal
                    ? `refunded (${(
                        payment.refundTotal.amount / 100
                      ).toLocaleString("en-AU", {
                        style: "currency",
                        currency: "AUD",
                      })})`
                    : payment.status
                }}
              </td>
              <td class="px-3 py-4 text-sm text-gray-500">
                {{ payment.psp }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
