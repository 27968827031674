import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase";

export namespace FirestoreService {
  export const getDocument = async <T>(path: string) => {
    const docRef = doc(db, path);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      return docSnapshot.data() as T;
    }
  };

  export const getCollection = async <T>(path: string) => {
    const collectionRef = collection(db, path);
    const docSnapshot = await getDocs(collectionRef);
    if (docSnapshot.empty) {
      return [];
    }
    return docSnapshot.docs.map((doc) => doc.data() as T);
  };
}
