<script setup lang="ts">
import { DateTime } from "luxon";
import { ref, onMounted, computed, watch } from "vue";
import { FirestoreService } from "../services/firestore";
import { PaymentSummary } from "../types/payment-summary";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowPathIcon,
  ArrowDownTrayIcon,
  InformationCircleIcon,
} from "@heroicons/vue/20/solid";
import { useLoading } from "../composables/useLoading";
import { useNotification } from "../composables/useNotification";
import CustomSelect from "../components/Common/CustomSelect.vue";
import { useCurrentUser } from "vuefire";
import { UserStore, useStores } from "../composables/useStores";
import StatsIcon from "../assets/images/stats.png";
import JsonCsv from "vue-json-csv";
const { setText, toggleLoading } = useLoading();
const { setError } = useNotification();
const { currentStore } = useStores();
const currentYear = ref(DateTime.now().toFormat("yyyy"));
const currentMonth = ref(DateTime.now().toFormat("MM"));
const currentYearMonth = computed(
  () => `${currentYear.value}-${currentMonth.value}`
);
const user = useCurrentUser();
const paymentSummaries = ref<PaymentSummary[]>([]);
const years = ref<string[]>([]);
const months = ref<string[]>([]);

const refresh = async (store: UserStore) => {
  setText("Loading payments...");
  toggleLoading(true);
  try {
    const path = `/users/${store.user}/merchants/${store.merchant}/stores/${store.store}/months/${currentYearMonth.value}/payments-summary`;
    paymentSummaries.value =
      await FirestoreService.getCollection<PaymentSummary>(path);
  } catch (e) {
    setError("failed to load payments please try again");
  } finally {
    toggleLoading(false);
  }
};
watch(
  [currentYearMonth, currentStore],
  async () => {
    if (!currentYearMonth.value) return;
    if (!user.value) return;
    if (!currentStore.value) return;
    await refresh(currentStore.value);
  },
  { immediate: true }
);

onMounted(() => {
  years.value = Array.from({ length: 5 }, (_, i) =>
    DateTime.now().minus({ years: i }).toFormat("yyyy")
  );
  months.value = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
});
const nextMonth = () => {
  const current = DateTime.fromFormat(currentYearMonth.value, "yyyy-MM").plus({
    months: 1,
  });
  currentYear.value = current.toFormat("yyyy");
  currentMonth.value = current.toFormat("MM");
};

const previousMonth = () => {
  const current = DateTime.fromFormat(currentYearMonth.value, "yyyy-MM").minus({
    months: 1,
  });
  currentYear.value = current.toFormat("yyyy");
  currentMonth.value = current.toFormat("MM");
};
const total = computed(() =>
  paymentSummaries.value.reduce((acc, summary) => acc + (summary.total ?? 0), 0)
);
const surcharge = computed(() =>
  paymentSummaries.value.reduce(
    (acc, summary) => acc + PaymentSummary.surcharge(summary),
    0
  )
);
const commission = computed(() =>
  paymentSummaries.value.reduce(
    (acc, summary) => acc + (summary.commission ?? 0),
    0
  )
);
const refunds = computed(() =>
  paymentSummaries.value.reduce(
    (acc, summary) => acc + (PaymentSummary.refund(summary) ?? 0),
    0
  )
);
const net = computed(() =>
  paymentSummaries.value.reduce(
    (acc, summary) => acc + (PaymentSummary.net(summary) ?? 0),
    0
  )
);
const stats = computed(() => [
  {
    id: 1,
    name: "Total",
    stat: (total.value / 100).toLocaleString("en-AU", {
      style: "currency",
      currency: "AUD",
    }),
    stat1: "AUD",
    change: "3%",
    changeType: "increase",
    // action: viewTodayPayments,
  },
  {
    id: 2,
    name: "Surcharge",
    stat: (surcharge.value / 100).toLocaleString("en-AU", {
      style: "currency",
      currency: "AUD",
    }),
    stat1: "AUD",
    change: "3%",
    changeType: "increase",
    // action: viewTodayPayments,
  },
  {
    id: 3,
    name: "Fees",
    stat: (commission.value / 100).toLocaleString("en-AU", {
      style: "currency",
      currency: "AUD",
    }),
    stat1: "AUD",
    change: "3%",
    changeType: "increase",
    // action: viewTodayPayments,
  },
  {
    id: 3,
    name: "Refunds",
    stat: (refunds.value / 100).toLocaleString("en-AU", {
      style: "currency",
      currency: "AUD",
    }),
    stat1: "AUD",
    change: "3%",
    changeType: "increase",
    // action: viewTodayPayments,
  },
  {
    id: 4,
    name: "Net",
    stat: (net.value / 100).toLocaleString("en-AU", {
      style: "currency",
      currency: "AUD",
    }),
    stat1: "AUD",
    change: "3%",
    changeType: "increase",
    // action: viewTodayPayments,
  },
]);
const monthMapping = {
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  September: "09",
  October: "10",
  November: "11",
  December: "12",
};
const selectedMonth = ref<keyof typeof monthMapping>(
  DateTime.now().toFormat("LLLL") as keyof typeof monthMapping
);
watch(selectedMonth, (selectedMonth) => {
  currentMonth.value = monthMapping[selectedMonth];
});

const downloadable = computed(() => {
  if (!paymentSummaries.value) return;
  if (paymentSummaries.value.length === 0) return;
  return JSON.stringify(
    paymentSummaries.value.map((payment) => {
      return {
        Date: payment.date,
        Total: (payment.total ?? 0) / 100,
        Surcharge: PaymentSummary.surcharge(payment) / 100,
        Fees: (payment.commission ?? 0) / 100,
        Refunds: (PaymentSummary.refund(payment) ?? 0) / 100,
        Net: (PaymentSummary.net(payment) ?? 0) / 100,
      };
    })
  );
});
</script>
<template>
  <div class="px-6 pb-8">
    <div class="flex flex-row justify-start items-center space-x-2">
      <InformationCircleIcon class="w-5 h-5 text-gray-400" />
      <p class="text-sm text-gray-400">
        Actual amount may differ due to refunds and chargebacks
      </p>
    </div>
    <div class="mb-6">
      <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-5">
        <div
          v-for="item in stats"
          :key="item.id"
          class="relative overflow-hidden rounded-lg bg-white p-6 shadow"
        >
          <div>
            <img :src="StatsIcon" class="w-[56px]" />
          </div>

          <!-- <p
            :class="[
              item.changeType === 'increase'
                ? 'text-green-600'
                : 'text-red-600',
              'ml-2 flex items-baseline text-sm font-semibold',
            ]"
          >
            <ArrowUpIcon
              v-if="item.changeType === 'increase'"
              class="h-5 w-5 flex-shrink-0 self-center text-green-500"
              aria-hidden="true"
            />
            <ArrowDownIcon
              v-else
              class="h-5 w-5 flex-shrink-0 self-center text-red-500"
              aria-hidden="true"
            />
            <span class="sr-only">
              {{ item.changeType === "increase" ? "Increased" : "Decreased" }}
              by
            </span>
            {{ item.change }}
          </p> -->
          <div class="flex flex-row justify-start items-center space-x-2 mt-4">
            <p class="text-3xl font-medium font-poppins text-[#111827]">
              {{ item.stat }}
            </p>

            <!-- <tag
              :label="item.tag"
              color="green"
              v-if="item.tag && item.tagCondition && item.tagCondition()"
            /> -->
          </div>

          <!-- <div
            class="flex flex-row justify-start items-center space-x-2 mt-4"
            v-if="item.stat1"
          >
            <p class="text-xl font-medium font-poppins text-[#111827]">
              {{ item.stat1 }}
            </p>
          </div> -->
          <div class="flex flex-row justify-between mt-4">
            <div>
              <p class="font-medium font-poppins text-base text-[#111827]">
                {{ item.name }}
              </p>
            </div>
            <!-- <div class="text-sm" v-if="item.action">
              <a
                @click.prevent="item.action"
                href=""
                class="text-primary underline font-medium text-base font-poppins cursor-pointer"
                >View payments<span class="sr-only">
                  {{ item.name }} stats</span
                ></a
              >
            </div> -->
          </div>
          <div
            class="flex flex-row justify-start items-center mt-1"
            v-if="item.name === 'Refunds'"
          >
            <InformationCircleIcon class="w-5 h-5 text-gray-400" />
            <p class="text-gray-400 text-xs">
              Historical refunds may not be included
            </p>
          </div>
        </div>
      </dl>
    </div>
    <div class="sm:flex sm:items-center mb-6">
      <div
        class="sm:flex-auto flex flex-row justify-start space-x-2 items-center"
      >
        <h1 class="text-[#111827] font-poppins font-semibold text-2xl">
          Payments
        </h1>
        <arrow-path-icon
          class="w-6 h-6 text-black block cursor-pointer"
          @click="currentStore && refresh(currentStore)"
        />
        <json-csv
          :data="downloadable"
          v-if="downloadable && currentStore"
          :name="`${currentStore.data.description}-payments-${currentYear}-${currentMonth}`"
        >
          <arrow-down-tray-icon
            class="w-6 h-6 text-black block cursor-pointer"
          />
        </json-csv>
      </div>
    </div>
    <div class="flex flex-row justify-between items-center mb-6">
      <ChevronLeftIcon
        class="h-16 w-16 text-black cursor-pointer"
        aria-hidden="true"
        @click="previousMonth"
      />
      <div class="text-xl flex flex-row justify-start items-center space-x-2">
        <custom-select
          v-model="currentYear"
          :options="years"
          class="w-[150px]"
        />
        <div class="h-[20px]"><p>-</p></div>
        <custom-select
          v-model="selectedMonth"
          :options="months"
          class="w-[150px]"
        />
      </div>
      <ChevronRightIcon
        class="h-16 w-16 text-black cursor-pointer"
        aria-hidden="true"
        @click="nextMonth"
      />
    </div>
    <div
      class="flex flex-col justify-start items-center"
      v-if="!paymentSummaries || paymentSummaries.length === 0"
    >
      <h1
        class="font-poppins text-xl font-semibold leading-6 text-gray-900 mt-6"
      >
        No payments for the period selected.
      </h1>
    </div>
    <div v-else class="p-4 bg-white rounded-[16px]">
      <div class="-mx-4 mt-4 sm:-mx-0">
        <table class="min-w-full">
          <thead class="border-none">
            <tr class="border-none">
              <th
                scope="col"
                class="rounded-l-[16px] hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Date
              </th>
              <th
                scope="col"
                class="rounded-l-[16px] hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Total
              </th>
              <th
                scope="col"
                class="hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Surcharge
              </th>
              <th
                scope="col"
                class="hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Fees
              </th>
              <th
                scope="col"
                class="hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Refunds
              </th>
              <th
                scope="col"
                class="hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Net
              </th>

              <th
                scope="col"
                class="rounded-r-[16px] hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                <span class="sr-only">Details</span>
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr
              v-for="payment in paymentSummaries.sort((p1, p2) =>
                p1.date > p2.date ? -1 : 1
              )"
              :key="payment.id"
            >
              <td
                class="w-full max-w-0 py-4 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0"
              >
                {{ payment.date }}
                <dl class="font-normal lg:hidden">
                  <dt class="sr-only">Total</dt>
                  <dd class="mt-1 truncate text-gray-700">
                    Total:{{
                      ((payment.total ?? 0) / 100).toLocaleString("en-AU", {
                        style: "currency",
                        currency: "AUD",
                      })
                    }}
                  </dd>
                  <dt class="sr-only">Surcharge</dt>
                  <dd class="mt-1 truncate text-gray-700">
                    Surcharge:{{
                      (PaymentSummary.surcharge(payment) / 100).toLocaleString(
                        "en-AU",
                        {
                          style: "currency",
                          currency: "AUD",
                        }
                      )
                    }}
                  </dd>
                  <dt class="sr-only">Fees</dt>
                  <dd class="mt-1 truncate text-gray-700">
                    Fees:{{
                      ((payment.commission ?? 0) / 100).toLocaleString(
                        "en-AU",
                        {
                          style: "currency",
                          currency: "AUD",
                        }
                      )
                    }}
                  </dd>
                  <dt class="sr-only">Refunds</dt>
                  <dd class="mt-1 truncate text-gray-700">
                    Refunds:{{
                      (PaymentSummary.refund(payment) / 100).toLocaleString(
                        "en-AU",
                        {
                          style: "currency",
                          currency: "AUD",
                        }
                      )
                    }}
                  </dd>
                  <dt class="sr-only">Net</dt>
                  <dd class="mt-1 truncate text-gray-700">
                    Net:{{
                      (PaymentSummary.net(payment) / 100).toLocaleString(
                        "en-AU",
                        {
                          style: "currency",
                          currency: "AUD",
                        }
                      )
                    }}
                  </dd>
                </dl>
              </td>
              <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                {{
                  ((payment.total ?? 0) / 100).toLocaleString("en-AU", {
                    style: "currency",
                    currency: "AUD",
                  })
                }}
              </td>
              <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {{
                  (PaymentSummary.surcharge(payment) / 100).toLocaleString(
                    "en-AU",
                    {
                      style: "currency",
                      currency: "AUD",
                    }
                  )
                }}
              </td>

              <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {{
                  ((payment.commission ?? 0) / 100).toLocaleString("en-AU", {
                    style: "currency",
                    currency: "AUD",
                  })
                }}
              </td>
              <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {{
                  ((PaymentSummary.refund(payment) ?? 0) / 100).toLocaleString(
                    "en-AU",
                    {
                      style: "currency",
                      currency: "AUD",
                    }
                  )
                }}
              </td>
              <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {{
                  ((PaymentSummary.net(payment) ?? 0) / 100).toLocaleString(
                    "en-AU",
                    {
                      style: "currency",
                      currency: "AUD",
                    }
                  )
                }}
              </td>

              <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <router-link
                  :to="{
                    name: 'store-payments',
                    params: { date: payment.date },
                  }"
                >
                  <a href="#" class="text-primary hover:text-primary"
                    >Details<span class="sr-only">, {{ payment.id }}</span></a
                  >
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
