import { NotificationRequestItem } from "@adyen/api-library/lib/src/typings/notification/notificationRequestItem";
import z from "zod";

export namespace Refund {
  export const schema = z.object({
    additionalData: z.record(z.string()).optional(),
    amount: z
      .object({
        currency: z.string().optional(),
        value: z.number().optional(),
      })
      .optional(),
    eventCode: z.nativeEnum(NotificationRequestItem.EventCodeEnum),
    eventDate: z.string().optional(),
    merchantAccountCode: z.string().optional(),
    merchantReference: z.string().optional(),
    originalReference: z.string().optional(),
    paymentMethod: z.string().optional(),
    pspReference: z.string(),
    reason: z.string().optional(),
    success: z.nativeEnum(NotificationRequestItem.SuccessEnum),
  });
}

export type Refund = z.infer<typeof Refund.schema>;

export namespace Chargeback {
  export const schema = z.object({
    additionalData: z.record(z.string()).optional(),
    amount: z
      .object({
        currency: z.string().optional(),
        value: z.number().optional(),
      })
      .optional(),
    eventCode: z.nativeEnum(NotificationRequestItem.EventCodeEnum),
    eventDate: z.string().optional(),
    merchantAccountCode: z.string().optional(),
    merchantReference: z.string().optional(),
    originalReference: z.string().optional(),
    paymentMethod: z.string().optional(),
    pspReference: z.string(),
    reason: z.string().optional(),
    success: z.nativeEnum(NotificationRequestItem.SuccessEnum),
  });
}

export type Chargeback = z.infer<typeof Chargeback.schema>;
