import { AggregateId } from "@weegigs/events-core";

export type StoreId = AggregateId<"store">;

export namespace StoreId {
  export const schema = AggregateId.schema("store").refine(
    (v) => v.key.startsWith("ST"),
    "Store id must start with SE"
  );
  export const create = (key: string): StoreId =>
    schema.parse({ type: "store", key });

  export const encode = AggregateId.encode;
  export const decode = (id: string) =>
    AggregateId.decoder("store")(id) as StoreId;

  export const fromAggregate = (id: AggregateId): StoreId => schema.parse(id);
}
