import { z } from "zod";

export namespace PaymentSummary {
  export const schema = z.object({
    bonus: z.number().optional(),
    commission: z.number().optional(),
    date: z.string(),
    id: z.string(),
    net: z.number().optional(),
    total: z.number().optional(),
    surcharge: z.number().optional(),
    referencedRefund: z.number().optional(), //positive
    unreferencedRefund: z.number().optional(), //negative
  });

  export type Type = z.infer<typeof schema>;
}

export type PaymentSummary = PaymentSummary.Type;

export namespace PaymentSummary {
  export const surcharge = (paymentSummary: PaymentSummary) => {
    if (paymentSummary.surcharge) {
      return paymentSummary.surcharge;
    }
    // if (!paymentSummary.bonus) {
    //   return 0;
    // }
    // if (!paymentSummary.commission) {
    //   return paymentSummary.bonus ?? 0;
    // }
    // return (paymentSummary.bonus ?? 0) + paymentSummary.commission;

    return 0;
  };

  export const refund = (paymentSummary: PaymentSummary) => {
    return (
      (paymentSummary.referencedRefund ?? 0) +
      (paymentSummary.unreferencedRefund ?? 0)
    );
  };

  export const net = (paymentSummary: PaymentSummary) => {
    return (paymentSummary.net ?? 0) - refund(paymentSummary);
  };
}
