<script setup lang="ts">
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/vue/20/solid";
import { DateTime } from "luxon";
import { computed, onMounted, ref, watch } from "vue";
import { useCurrentUser } from "vuefire";
import CustomSelect from "../components/Common/CustomSelect.vue";
import Tag from "../components/Common/Tag.vue";
import { useLoading } from "../composables/useLoading";
import { useNotification } from "../composables/useNotification";
import { UserStore, useStores } from "../composables/useStores";
import { FirestoreService } from "../services/firestore";
import { Transfer } from "../types/transfer";
import JsonCsv from "vue-json-csv";
import { Store } from "../types/store";

const { setText, toggleLoading } = useLoading();
const { setError } = useNotification();
const { currentStore } = useStores();
const statusMapper = (status: string) => {
  if (status === "authorised") {
    return "Paid out";
  }
  if (status === "booked") {
    return "Paid out";
  }
  return status;
};
const currentYear = ref(DateTime.now().toFormat("yyyy"));
const currentMonth = ref(DateTime.now().toFormat("MM"));
const currentYearMonth = computed(
  () => `${currentYear.value}-${currentMonth.value}`
);
const user = useCurrentUser();
const payoutSummaries = ref<Transfer[]>([]);
const years = ref<string[]>([]);
const months = ref<string[]>([]);
const filterByBalanceAccount = (transfer: Transfer) => {
  if (!currentStore.value) return false;
  if (!currentStore.value.data.balanceAccount) return true;
  if (!transfer.balanceAccount) return false;
  return transfer.balanceAccount.id === currentStore.value.data.balanceAccount;
};
const zone = (store: Store) => {
  if (store.address?.state === "VIC") {
    return "Australia/Melbourne";
  }
  return "Australia/Brisbane";
};
const refresh = async (store: UserStore) => {
  setText("Loading payouts...");
  toggleLoading(true);
  try {
    const path = `/users/${store.user}/merchants/${store.merchant}/transfers/months/${currentYearMonth.value}`;
    const result = await FirestoreService.getCollection<Transfer>(path);
    console.log(JSON.stringify(result));
    payoutSummaries.value = result.filter(filterByBalanceAccount);
  } catch (e) {
    console.log(e);
    setError("failed to load payouts please try again");
  } finally {
    toggleLoading(false);
  }
};
watch(
  [currentYearMonth, currentStore],
  async () => {
    if (!currentYearMonth.value) return;
    if (!user.value) return;
    if (!currentStore.value) return;
    await refresh(currentStore.value);
  },
  { immediate: true }
);

onMounted(() => {
  years.value = Array.from({ length: 5 }, (_, i) =>
    DateTime.now().minus({ years: i }).toFormat("yyyy")
  );
  months.value = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
});
const nextMonth = () => {
  const current = DateTime.fromFormat(currentYearMonth.value, "yyyy-MM").plus({
    months: 1,
  });
  currentYear.value = current.toFormat("yyyy");
  currentMonth.value = current.toFormat("MM");
};

const previousMonth = () => {
  const current = DateTime.fromFormat(currentYearMonth.value, "yyyy-MM").minus({
    months: 1,
  });
  currentYear.value = current.toFormat("yyyy");
  currentMonth.value = current.toFormat("MM");
};
const monthMapping = {
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  September: "09",
  October: "10",
  November: "11",
  December: "12",
};
const selectedMonth = ref<keyof typeof monthMapping>(
  DateTime.now().toFormat("LLLL") as keyof typeof monthMapping
);
watch(selectedMonth, (selectedMonth) => {
  currentMonth.value = monthMapping[selectedMonth];
});

const downloadable = computed(() => {
  if (!payoutSummaries.value) return;
  if (payoutSummaries.value.length === 0) return;
  return JSON.stringify(
    payoutSummaries.value.map((payment) => {
      if (!currentStore.value) return;
      return {
        Date: DateTime.fromISO(payment.created)
          .setZone(zone(currentStore.value.data))
          .toFormat("yyyy-MM-dd HH:mm:ss"),
        Amount: (payment.amount.value ?? 0) / 100,
      };
    })
  );
});
</script>
<template>
  <div class="p-6">
    <!-- <a href="/dashboard" class="bg-primary p-2 inline-block mb-2">
      <ChevronLeftIcon class="h-8 w-8 text-white" aria-hidden="true" />
    </a> -->

    <div class="sm:flex sm:items-center mb-6">
      <div
        class="sm:flex-auto flex flex-row justify-start space-x-2 items-center"
      >
        <h1 class="text-[#111827] font-poppins font-semibold text-2xl">
          Payouts
        </h1>
        <arrow-path-icon
          class="w-6 h-6 text-black block cursor-pointer"
          @click="currentStore && refresh(currentStore)"
        />
        <json-csv
          :data="downloadable"
          v-if="downloadable && currentStore"
          :name="`${currentStore.data.description}-payouts-${currentYear}-${currentMonth}`"
        >
          <arrow-down-tray-icon
            class="w-6 h-6 text-black block cursor-pointer"
          />
        </json-csv>
      </div>
    </div>
    <div class="flex flex-row justify-between items-center mb-6">
      <ChevronLeftIcon
        class="h-16 w-16 text-black cursor-pointer"
        aria-hidden="true"
        @click="previousMonth"
      />
      <div class="text-xl flex flex-row justify-start items-center space-x-2">
        <custom-select
          v-model="currentYear"
          :options="years"
          class="w-[150px]"
        />
        <div class="h-[20px]"><p>-</p></div>
        <custom-select
          v-model="selectedMonth"
          :options="months"
          class="w-[150px]"
        />
      </div>
      <ChevronRightIcon
        class="h-16 w-16 text-black cursor-pointer"
        aria-hidden="true"
        @click="nextMonth"
      />
    </div>
    <div
      class="flex flex-col justify-start items-center"
      v-if="!payoutSummaries || payoutSummaries.length === 0"
    >
      <h1
        class="font-poppins text-xl font-semibold leading-6 text-gray-900 mt-6"
      >
        No payouts for the period selected.
      </h1>
    </div>
    <div v-else class="p-4 bg-white rounded-[16px]">
      <div class="-mx-4 mt-4 sm:-mx-0">
        <table class="min-w-full" v-if="currentStore">
          <thead class="border-none">
            <tr class="border-none">
              <th
                scope="col"
                class="rounded-l-[16px] hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Date
              </th>
              <th
                scope="col"
                class="rounded-l-[16px] hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Amount
              </th>
              <th
                scope="col"
                class="hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr
              v-for="payment in payoutSummaries.sort((p1, p2) =>
                DateTime.fromISO(p2.created).toMillis() -
                  DateTime.fromISO(p1.created).toMillis() >
                0
                  ? 1
                  : -1
              )"
              :key="payment.id"
            >
              <td
                class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0"
              >
                {{
                  DateTime.fromISO(payment.created)
                    .setZone(zone(currentStore.data))
                    .toFormat("yyyy-MM-dd HH:mm:ss")
                }}
                <dl class="font-normal lg:hidden">
                  <dt class="sr-only">Total</dt>
                  <dd class="mt-1 truncate text-gray-700">
                    Amount:{{
                      ((payment.amount.value ?? 0) / 100).toLocaleString(
                        "en-AU",
                        {
                          style: "currency",
                          currency: "AUD",
                        }
                      )
                    }}
                  </dd>
                </dl>
              </td>
              <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                {{
                  ((payment.amount.value ?? 0) / 100).toLocaleString("en-AU", {
                    style: "currency",
                    currency: "AUD",
                  })
                }}
              </td>

              <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                <tag
                  :label="statusMapper(payment.status)"
                  :color="
                    payment.status === 'booked' ||
                    payment.status === 'authorised'
                      ? 'green'
                      : 'red'
                  "
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
