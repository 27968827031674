<script setup lang="ts">
import { useRoute } from "vue-router";
import { DateTime } from "luxon";
import { ref, onMounted, computed, watch } from "vue";
import { FirestoreService } from "../services/firestore";
import { PaymentSummary } from "../types/payment-summary";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";
import { useLoading } from "../composables/useLoading";
import { useNotification } from "../composables/useNotification";
import CustomSelect from "../components/Common/CustomSelect.vue";

const route = useRoute();
const { setText, toggleLoading } = useLoading();
const { setError } = useNotification();
const currentYear = ref(DateTime.now().toFormat("yyyy"));
const currentMonth = ref(DateTime.now().toFormat("MM"));
const currentYearMonth = computed(
  () => `${currentYear.value}-${currentMonth.value}`
);
const paymentSummaries = ref<PaymentSummary[]>([]);
const years = ref<string[]>([]);
const months = ref<string[]>([]);
const refresh = async () => {
  setText("Loading payments...");
  toggleLoading(true);
  try {
    const path = `/users/${route.query.user}/merchants/${route.params.merchant}/stores/${route.params.store}/months/${currentYearMonth.value}/payments-summary`;
    paymentSummaries.value =
      await FirestoreService.getCollection<PaymentSummary>(path);
  } catch (e) {
    setError("failed to load payments please try again");
  } finally {
    toggleLoading(false);
  }
};
watch(
  currentYearMonth,
  async () => {
    if (!currentYearMonth.value) return;
    console.log("currentYearMonth.value", currentYearMonth.value);
    await refresh();
  },
  { immediate: true }
);

onMounted(() => {
  years.value = Array.from({ length: 5 }, (_, i) =>
    DateTime.now().minus({ years: i }).toFormat("yyyy")
  );
  months.value = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
});
const nextMonth = () => {
  const current = DateTime.fromFormat(currentYearMonth.value, "yyyy-MM").plus({
    months: 1,
  });
  currentYear.value = current.toFormat("yyyy");
  currentMonth.value = current.toFormat("MM");

  refresh();
};

const previousMonth = () => {
  const current = DateTime.fromFormat(currentYearMonth.value, "yyyy-MM").minus({
    months: 1,
  });
  currentYear.value = current.toFormat("yyyy");
  currentMonth.value = current.toFormat("MM");
  refresh();
};
</script>
<template>
  <div class="p-6">
    <a href="/dashboard" class="bg-primary p-2 inline-block mb-2">
      <ChevronLeftIcon class="h-8 w-8 text-white" aria-hidden="true" />
    </a>
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-[#111827] font-poppins font-semibold text-2xl mb-6">
          Payments
        </h1>
      </div>
    </div>
    <div class="flex flex-row justify-between items-center mb-6">
      <ChevronLeftIcon
        class="h-16 w-16 text-black cursor-pointer"
        aria-hidden="true"
        @click="previousMonth"
      />
      <div class="text-xl flex flex-row justify-start items-center space-x-2">
        <custom-select v-model="currentYear" :options="years" class="w-30" />
        <div class="h-[20px]"><p>-</p></div>
        <custom-select v-model="currentMonth" :options="months" class="w-30" />
      </div>
      <ChevronRightIcon
        class="h-16 w-16 text-black cursor-pointer"
        aria-hidden="true"
        @click="nextMonth"
      />
    </div>
    <div
      class="flex flex-col justify-start items-center"
      v-if="!paymentSummaries || paymentSummaries.length === 0"
    >
      <h1
        class="font-poppins text-xl font-semibold leading-6 text-gray-900 mt-6"
      >
        No payments for the period selected.
      </h1>
    </div>
    <div v-else class="p-4 bg-white rounded-[16px]">
      <div class="-mx-4 mt-4 sm:-mx-0">
        <table class="min-w-full">
          <thead class="border-none">
            <tr class="border-none">
              <th
                scope="col"
                class="rounded-l-[16px] hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Date
              </th>
              <th
                scope="col"
                class="rounded-l-[16px] hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Total
              </th>
              <th
                scope="col"
                class="hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Surcharge
              </th>
              <th
                scope="col"
                class="hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Fees
              </th>
              <th
                scope="col"
                class="hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                Net
              </th>

              <th
                scope="col"
                class="rounded-r-[16px] hidden sm:table-cell table-header font-poppins text-[12px] font-medium text-[#687588]"
              >
                <span class="sr-only">Details</span>
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <tr
              v-for="payment in paymentSummaries.sort((p1, p2) =>
                p1.date > p2.date ? -1 : 1
              )"
              :key="payment.id"
            >
              <td
                class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0"
              >
                {{ payment.date }}
                <dl class="font-normal lg:hidden">
                  <dt class="sr-only">Total</dt>
                  <dd class="mt-1 truncate text-gray-700">
                    Total:{{
                      ((payment.total ?? 0) / 100).toLocaleString("en-AU", {
                        style: "currency",
                        currency: "AUD",
                      })
                    }}
                  </dd>
                  <dt class="sr-only">Surcharge</dt>
                  <dd class="mt-1 truncate text-gray-700">
                    Surcharge:{{
                      (PaymentSummary.surcharge(payment) / 100).toLocaleString(
                        "en-AU",
                        {
                          style: "currency",
                          currency: "AUD",
                        }
                      )
                    }}
                  </dd>
                  <dt class="sr-only">Fees</dt>
                  <dd class="mt-1 truncate text-gray-700">
                    Fees:{{
                      ((payment.commission ?? 0) / 100).toLocaleString(
                        "en-AU",
                        {
                          style: "currency",
                          currency: "AUD",
                        }
                      )
                    }}
                  </dd>
                  <dt class="sr-only">Net</dt>
                  <dd class="mt-1 truncate text-gray-700">
                    Net:{{
                      ((payment.net ?? 0) / 100).toLocaleString("en-AU", {
                        style: "currency",
                        currency: "AUD",
                      })
                    }}
                  </dd>
                </dl>
              </td>
              <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                {{
                  ((payment.total ?? 0) / 100).toLocaleString("en-AU", {
                    style: "currency",
                    currency: "AUD",
                  })
                }}
              </td>
              <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {{
                  (PaymentSummary.surcharge(payment) / 100).toLocaleString(
                    "en-AU",
                    {
                      style: "currency",
                      currency: "AUD",
                    }
                  )
                }}
              </td>

              <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {{
                  ((payment.commission ?? 0) / 100).toLocaleString("en-AU", {
                    style: "currency",
                    currency: "AUD",
                  })
                }}
              </td>
              <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                {{
                  ((payment.net ?? 0) / 100).toLocaleString("en-AU", {
                    style: "currency",
                    currency: "AUD",
                  })
                }}
              </td>

              <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <a href="#" class="text-indigo-600 hover:text-indigo-900"
                  >Details<span class="sr-only">, {{ payment.id }}</span></a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
