import { z } from "zod";
import { Money } from "./money";
import { StoreId } from "./store-id";
import { Refund, Chargeback } from "./refund";
export namespace Payment {
  export const captureTransfer = z.object({
    amount: Money.schema,
    status: z.string(),
    sequence: z.number(),
    id: z.string(),
    reason: z.string(),
  });
  export const status = z.union([
    z.literal("authorised"),
    z.literal("captured"),
  ]);

  export const fields = z.object({
    id: z.string().min(1),
    psp: z.string().min(1),
    merchantReference: z.string().min(1),
    date: z.string(),
    paymentMethod: z.string(),
    status,
    calculatedCommission: Money.schema,
    amount: Money.schema,
    merchantAccountCode: z.string().min(1),
    store: StoreId.schema,
    refunds: z.array(Refund.schema).optional(),
    chargebacks: z.array(Chargeback.schema).optional(),
    additionalData: z.any().optional(),
    refundTotal: Money.schema.optional(),
    transfers: z
      .object({
        tip: captureTransfer.optional(),
        surcharge: captureTransfer.optional(),
        commission: captureTransfer.optional(),
        merchantReceived: captureTransfer.optional(),
        additionalCommission: captureTransfer.optional(),
        paymentFee: captureTransfer.optional(),
      })
      .optional(),
  });

  export type Type = z.infer<typeof fields>;
}

export type Payment = Payment.Type;

export namespace Payment {
  export const tip = (payment: Payment) =>
    (payment.transfers?.tip?.amount.amount ?? 0) / 100;
  export const surcharge = (payment: Payment) =>
    payment.additionalData?.extraCostsValueSurcharge ?? 0;
  export const commission = (payment: Payment) =>
    payment.calculatedCommission?.amount ?? 0;
  export const net = (payment: Payment) =>
    total(payment) -
    (surcharge(payment) == 0 ? commission(payment) : surcharge(payment));

  export const merchantReceived = (payment: Payment) =>
    (payment.transfers?.merchantReceived?.amount.amount ?? 0) / 100;

  export const additionalCommission = (payment: Payment) =>
    (payment.transfers?.additionalCommission?.amount.amount ?? 0) / 100;
  export const fees = (payment: Payment) =>
    (payment.transfers?.paymentFee?.amount.amount ?? 0) / 100;
  export const afterFees = (payment: Payment) =>
    tip(payment) + surcharge(payment) + merchantReceived(payment);
  export const original = (payment: Payment) =>
    merchantReceived(payment) +
    commission(payment) +
    additionalCommission(payment);
  export const total = (payment: Payment) => payment.amount.amount;
}
