import { ref } from "vue";
import { Store } from "../types/store";
export type UserStore = {
  store: string;
  user: string;
  merchant: string;
  data: Store;
};
export const initialize = () => {
  const stores = ref<UserStore[]>([]);
  const currentStore = ref<UserStore>();
  const setStores = (items: UserStore[]) => {
    stores.value = items;
  };
  const setCurrentStore = (store: UserStore) => {
    currentStore.value = store;
    localStorage.setItem("currentStore", JSON.stringify(store));
  };

  return () => ({
    stores,
    currentStore,
    setStores,
    setCurrentStore,
  });
};

export const useStores = initialize();
