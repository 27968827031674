import { createApp } from "vue";
import { VueFire, VueFireAuth } from "vuefire";
import App from "./App.vue";
import router from "./router";
import { app as firebaseApp } from "./services/firebase";
import FloatingVue from "floating-vue";
// import * as Sentry from "@sentry/vue";

import "./style.css";
import "floating-vue/dist/style.css";
// import "./common.css";
// import "./theme-green.css";
const app = createApp(App);
// Sentry.init({
//   app,
//   dsn: "https://f3cf777d01939242a72a62b7e1d72a00@o4507014209273856.ingest.us.sentry.io/4507014213271552",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
app.use(VueFire, {
  firebaseApp,
  modules: [VueFireAuth()],
});

app.use(router).mount("#app");
FloatingVue.options.themes.tooltip.placement = "top";
FloatingVue.options.distance = 15;
app.use(FloatingVue);
