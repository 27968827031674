import { z } from "zod";

export namespace Cents {
  export const schema: z.Schema<Cents> = z.number().int();

  export const validate = (value: unknown): value is Cents => {
    return schema.safeParse(value).success;
  };

  export const toDollars = (cents: Cents): number => {
    return cents / 100;
  };

  export const fromDollars = (dollars: number): Cents => {
    return Math.round(dollars * 100);
  };
}

export type Cents = number;

export namespace Currency {
  export const schema = z.literal("aud");
  export type Type = z.infer<typeof schema>;
}
export type Currency = Currency.Type;

export namespace Money {
  export const schema = z.object({
    amount: Cents.schema,
    currency: Currency.schema,
  });

  export type Type = z.infer<typeof schema>;

  export const create = (amount: Cents): Money => ({
    amount,
    currency: "aud" as "aud",
  });

  export const substract = (money1: Money, money2: Money): Money => ({
    amount: money1.amount - money2.amount,
    currency: "aud" as "aud",
  });
  export const add = (money1: Money, money2: Money): Money => ({
    amount: money1.amount + money2.amount,
    currency: "aud" as "aud",
  });

  export const Nil = Money.create(0);
}

export type Money = Money.Type;

export namespace Percentage {
  export const schema = z.number();

  export type Type = z.infer<typeof schema>;

  export const validate = (value: unknown): value is Percentage => {
    return schema.safeParse(value).success;
  };
}

export type Percentage = Percentage.Type;
